body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   scroll-behavior: smooth;
}

::-webkit-scrollbar {
   height: 8px;
   width: 8px;
   background: #e7e7e7;
   -webkit-border-radius: 1ex;
   border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
   background: #c1c1c1;
   -webkit-border-radius: 1ex;
   border-radius: 1ex;
}

/* ::-webkit-scrollbar-corner {
   /* background: #fff; 
} */

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.MuiCardHeader-action {
   margin: 0 !important;
}
